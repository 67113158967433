import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import heroimage from "../../assets/hero-image.png";
import "./Hero.css";

const Hero: React.FC = () => {
  return (
    <div className="hero-container">
      <div className="sub-container">
        <h2 className="hero-subtitle">Products / Restaurants / Best Prices</h2>
        <h3>
          Only <span>Gluten</span> Free
        </h3>
        {/*<Link to="/#products" className="nav-item">
          <button className="button-hero">Shop Now</button>
        </Link>*/}
      </div>
      <img src={heroimage} className="hero-image" alt="heroimage" />
    </div>
  );
};

export default Hero;
