import React, { useState } from "react";
import Navbar from "./Navbar/Navbar";
import Hero from "./HeroHeader/Hero";
import Restaurants from "./Restaurants/Restaurants";
import Products from "./Products/Products";
import Providers from "./Providers/Providers";
import Footer from "./Footer/Footer";

const Home: React.FC = () => {
  const [query, setQuery] = useState<string>("");

  const handleSearch = (newQuery: string) => {
    setQuery(newQuery);
  };

  return (
    <div>
      <Navbar />
      <Hero />
      <Restaurants />
      <Products />
      <Providers />
      <Footer />
    </div>
  );
};

export default Home;
