import React from "react";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import "./Providers.css";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const Providers: React.FC = () => {
  const data = {
    labels: ["Bilka", "Nemlig", "Føtex"],
    datasets: [
      {
        label: "Top ten providers",
        data: ["1002", "853", "704"],
        backgroundColor: ["#0F223B", "#024A94", "#FFD950"],
        borderColor: ["#000", "#000", "#000"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <>
      <div className="providers-container">
        <h1 className="providers-title">TOP PRODUCT PROVIDERS</h1>
        <div className="chart-container">
          <Bar className="bar" data={data} options={options}></Bar>
        </div>
      </div>
    </>
  );
};

export default Providers;
