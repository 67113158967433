import React from "react";
import useProductDetail from "../../hooks/useProductDetail";
import { useParams } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import "./ProductDetail.css";
import Footer from "../Footer/Footer";

const ProductDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { product, loading, error } = useProductDetail(Number(id));

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (!product) {
    return <p>Product not found</p>;
  }

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  return (
    <>
      <Navbar />
      <div className="product-detail">
        <div className="image-container">
          <img
            src={product.Image}
            alt={product.Name}
            className="product-detailed-image"
          />
        </div>
        <div className="product-detailed-items">
          <h1 className="product-detailed-name">
            {" "}
            {capitalizeFirstLetter(product.Name)}
          </h1>
          <p className="product-detailed-category">{product.Category}</p>
          <p className="product-detailed-price">{product.Price}</p>
          <p className="product-detailed-description">
            {product.ProductDescription}
          </p>
          <a href={product.Url} target="_blank">
            <button className="link-button">Go to shop</button>
          </a>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProductDetail;
