import React, { useEffect, useState } from "react";
/*import { getRestaurants } from "../../services/RestaurantApi";
import { RestaurantItemType } from "../../types/Restaurants";*/
import "./Restaurants.css";
import axios from "axios";

interface Restaurant {
  id: number;
  name: string;
  img: string;
  url: string;
}

const Restaurants: React.FC = () => {
  const [restaurants, setRestaurants] = useState<Restaurant[]>([]);

  useEffect(() => {
    axios
      .get("/restaurants.json")
      .then((response) => {
        setRestaurants(response.data);
      })
      .catch((error) => {
        console.error("Error fetching the restaurants:", error);
      });
  }, []);

  return (
    <div id="restaurants">
      <h1 className="restaurants-title">Restaurants</h1>
      <div className="cards-container">
        {restaurants.map((restaurant) => (
          <div key={restaurant.id} className="restaurant-card">
            <a href={restaurant.url} target="_blank">
              <img
                src={restaurant.img}
                alt={restaurant.name}
                className="restaurant-img"
              />
            </a>
            <div className="card-title">{restaurant.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Restaurants;
