import React from "react";
import "./Footer.css";
import logo from "../../assets/logo.png";
import { HashLink as Link } from "react-router-hash-link";

const Footer: React.FC = () => {
  return (
    <>
      <div className="line"></div>
      <div className="footer">
        <div className="logo-footer">
          <img src={logo} alt="Logo" />
        </div>
        <p className="footer-title">© No Gluten Portal</p>
      </div>
    </>
  );
};

export default Footer;
